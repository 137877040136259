// COMPONENT - .overlay-{name}
// ================================================================

.overlay-container {
	position: relative;
	display: block;
	overflow:hidden;
	@include breakpoint (medium) {
		&:hover *[class^="overlay-"] {
			opacity: 1;
			top: -1px;
			bottom: -1px;
			left: -1px;
			right: -1px;
			width:100%;
			height: 100%;
			@include transition() 
		}	
	}
}

@mixin overlay ($bc:black,$c:white, $hover:none) {
	content:"";
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	text-align: center;
	position: absolute;
	width: 100%; height: 100%;
	top:0; bottom: 0;
	opacity: 0;
	//z-index:-1;
	p {
		margin: 0;
	}

	// Use Custom Colors
	@if $bc { background-color: $bc };
	@if $c { 
		color: $c;
		#{$headers},p,a {
			color: $c;
		}
	};

	// If it uses hover, activate transistion
	@if $hover == none {
		opacity: 1;
	}

	// fade-in the overlay on hover
	@if $hover == fade { 
		@include breakpoint(medium) {
			opacity: 0;
			@include transition()
		};
	}

	// open the overlay on hover
	@if $hover == slide { 
		@include breakpoint(medium) {
			width: 0;
			top: -100%;
			opacity: 0;
			@include transition()
		};
	}
		
}