a.totop {
	$size: 40px;
	$bg-color: $secondary-color;
	$color: $white;
	$bg-color-hover: $white;
	$color-hover: $black;
	$border-radius: 50%;

	display:none;
	position: fixed;
	z-index:201;
	bottom:20px;
	right: 20px;
	width: $size;
	height: $size;
	line-height: $size;
	border-radius: $border-radius;
	text-align: center;
	color: $color;
	background-color: $bg-color;
	cursor: pointer;
	font-size: rem-calc(13);
	@include box-shadow();
	@include transition();
	&:hover {
		@include transition();
		background-color: $bg-color-hover;
		color: $color-hover;
	}
	&.fade-in {
		@include mui-animation(zoom, fade, slide($direction:up));
		animation-duration: 200ms;
		display: block;
	}
}