// Base
// ================================================================

*:focus { outline: none }

.row.wide { max-width: calculateRem($wide-width)}

.fancybox-thumbs { background-color: $black }
.fancybox-thumbs > ul > li.fancybox-thumbs-active:before { border:4px solid $primary-color }

*[data-interchange] {
	position: relative;
	background-size: cover;
	background-position: center center;
	z-index:0;
}

.button { @include button-theme() }

iframe.snazzymap {
	border:none;
	height: 250px;
	@include breakpoint(large) {
		height: 500px
	}
}

.inverted,
.inverted h1,
.inverted h2,
.inverted h3,
.inverted h4,
.inverted h5,
.inverted h6,
.inverted p,
.inverted a,
 {
	color: $white;
	color: rgba(255,255,255,0.7);
	&:hover, &:focus {
		color: $white;
		color: rgba(255,255,255,0.7);
	}
}

#gmap {
	height: 300px;
	@include breakpoint(medium) {
		height: 500px;
	}
}
//Wrap this around table to make it overflow scroll horizontaly
.table-box { 
	overflow-x: auto;
}
