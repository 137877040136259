// MIXINS
// ================================================================

@mixin transition($speed:300ms) {
	transition: all ($speed) ease-in-out;
}

@mixin transform($scale:1,$rotate:0deg,$translate-x:0px,$translate-y:0px,$skew-x:0deg,$skew-y:0deg) {
	transform: scale($scale) rotate($rotate) translateX($translate-x) translateY($translate-y) skewX($skew-x) skewY($skew-y);
}

@mixin box-shadow($h:1px,$v:12px,$b:15px,$s:-5px,$c:rgba(0, 0, 0, 0.1)) {
	box-shadow: $h $v $b $s $c;
}

@mixin ul($direction) {
 list-style: none;
 margin-left: 0;
	@if $direction == 'horizontal' {
		li { display: inline-block }
	}
}

@mixin color ($font:$body-font-color, $header:$header-color, $link:$anchor-color) {
	color: $font;
	p,li {
		color: $font;
	}
	h1,h2,h3,h4,h5,h6 {
		color: $header;
	}
	a {
		color: $link;
	}
}