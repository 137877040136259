// Header
// ================================================================
.header {
	position: relative;
	> .row {
		position: relative;
	 	min-height: $header-height-small;
		@include breakpoint($breakpoint)  {
		min-height: $header-height;
		}
		@include breakpoint(landscape) {
			min-height: $header-height;
		}
	}
	
	&.trans {
		margin-top: -($top-height + 1px);
		.header--spacer {
			margin-top: ($top-height + 1px);
		}
	}
	overflow: hidden;
}