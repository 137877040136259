// Color
// ================================================================
@mixin color ($font:$body-font-color, $header:$header-color, $link:$anchor-color) {
	color: $font;
	p,li {
		color: $font;
	}
	h1,h2,h3,h4,h5,h6 {
		color: $header;
	}
	a {
		color: $link;
	}
}

@mixin color-invert() {
	p,li,i,strong { color: rgba(white,0.7) }
	h1,h2,h3,h4,h5,h6 { color: rgba(white,1) }
	a { color: rgba(white, 1) }
}