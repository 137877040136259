// Shortcodes
.booster {
  margin-top: $global-margin;
  margin-bottom: $global-margin;
  border-top: 2px solid $light-gray;
  border-bottom: 2px solid $light-gray;
  padding-top: $global-margin;
  padding-bottom: $global-margin;
  font-size: 18px;
  @include breakpoint(xlarge) {
    width: 120%;
    margin-left: -10%;
    margin-top: $global-margin * 2;
    margin-bottom: $global-margin * 2;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  img {
    margin-bottom: $global-margin;
    border-radius: 2px;
  }
}

.inline-video {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 58%;
  margin-bottom: $global-margin * 2;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @include breakpoint(large) {
    width: 108%;
    margin-left: -4%;
  }
}

// Stuff
// ----------------------------------------------------

.full-video {
  margin: 20px;
}

.fa-primary {
  color: $primary-color;
}
.label.alt {
  background-color: #c3c520;
  position: absolute;
  z-index: 10;
  left: 5%;
  top: 5px;
  padding: 0.8rem;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 800;
  transform: rotateZ(-10deg);
  span {
    font-size: 0.8rem;
    font-weight: 300;
  }
}

.c2a-campaign {
  display: block;
  width: 360px;
  max-width: 90%;
  color: white;
  padding: 2rem 3rem;
  background-color: $third-color;
  margin: 0 auto;
  transform: translateY(-15px);
  border-radius: 5px;
  h3 {
    margin-bottom: 5px;
  }
  @include breakpoint(large) {
    position: absolute;
    left: 53%;
    top: -30px;
    transform: translateY(-100%);
  }
}

// Headers
//-----------------------------------------------------
header.header {
  text-align: left;
  &[data-interchange] {
    background-position: center;
  }
  &.hidden,
  &.none {
    display: none;
  }
  &.home {
    @include header-height($hh: 460px, $hhb: 150px);
  }
  &.list {
    @include header-height($hh: 200px, $hhb: 0);
    background-color: $primary-color;
  }
  &.fullscreen {
    @include header-height($hh: calc(100vh - #{$top-height}), $hhb: 150px);
  }
  .header--box {
    text-align: center;
    padding: 50px 0;
    max-width: 800px;
    background-color: transparent;
    @include breakpoint(medium) {
      text-align: center;
      margin: 0 auto;
    }
  }
  .header--overlay {
    @include overlay();
    @include linear-gradient(135deg, #223e80 0%, #5281aa 50%, #b4e391 100%);
    opacity: 0.8;
  }
  .header--skew {
    display: none;
    position: absolute;
    width: 130vw;
    left: -10vw;
    height: 10vw;
    background-color: $black;
    bottom: 0;
    transform: rotateZ(-3deg) translateY(7vw);
  }
  h2.pretitle {
    margin-bottom: 5px;
    font-weight: 400;
    color: $white;
    @include breakpoint(large) {
      font-size: rem-calc(18);
    }
  }
  h1 {
    margin: 0;
    font-weight: 600;
    color: $white;
    @include breakpoint(large) {
      font-size: rem-calc(55);
    }
  }
  p {
    margin-bottom: 0;
    color: rgba(white, 1);
  }
  a {
    display: block;
    margin-top: 20px;
    color: white;
    text-decoration: underline;
  }
  h1 + p {
    margin-top: 20px;
  }

  a.button {
    font-size: rem-calc(18);
    font-weight: 400;
    @include box-shadow() padding: 0.8em 1.6em;
    border-radius: 5px;
  }

  &.page {
    @include header-height($hh: 200px, $hhb: 200px) text-align: left;
    h1 {
      font-size: rem-calc(22);
    }
  }
}

header.header2 {
  background-color: $primary-color;
  padding: 45px 0;
  h1 {
    color: $white;
    margin-bottom: 0;
    font-weight: 400;
  }
  p {
    margin-bottom: 0;
    color: rgba(white, 0.5);
  }
  p a {
    color: white;
    text-decoration: underline;
  }
}

// Top
//-----------------------------------------------------
section.top {
  @include box-shadow(0, 0, 50px) border-bottom: 4px solid $secondary-color;
  &.is-stuck {
    background-color: $light-gray;
    @include box-shadow(0, 0, 50px);
  }
}

// Default Section Colors
//-----------------------------------------------------

.bg-white-boxed {
  @include bg-boxed();
  @include box-shadow($h: 0px, $v: -20px, $b: 100px, $c: rgba($black, 0.1));
  background: $white;
}
.bg-light-gray-boxed {
  background-color: $light-gray;
  @include bg-boxed();
}
.bg-light-gray {
  background-color: $light-gray;
  border-bottom: 1px solid mix(black, $light-gray, 10);
}
.bg-white {
  background-color: $white;
}
.bg-medium-gray {
  background-color: $medium-gray;
  @include color-invert();
}
.bg-dark-gray {
  background-color: $dark-gray;
  @include color-invert();
}
.bg-primary {
  background-color: $primary-color;
  @include color-invert();
}
.bg-secondary {
  background-color: $secondary-color;
  @include color-invert();
}
.bg-gradient {
  @include linear-gradient(to left, $primary-color 0%, $secondary-color 100%)
    @include color-invert();
}
.bg-red {
  background-color: #d0453c;
  @include color-invert();
}

// Hero
// ================================================================
// Use above and outside ROW class. Ex: <div class="hero-img large-6" style="background-image: url('/img/theme/trucks.jpg')"></div>

.hero {
  position: relative;
  > .row {
    @include breakpoint(large) {
      min-height: 500px;
    }
  }
}
.hero-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: $light-gray;
  background-size: cover;
  background-position: 50% 50%;
  @include breakpoint(small only) {
    position: static;
    min-height: 200px;
  }
  &.hero-img-right {
    right: 0;
  }
  &.hero-img-left {
    left: 0;
  }
  &.focus-right {
    background-position: 100% 50%;
  }
}
.hero-body-right {
  @include breakpoint(medium) {
    padding-left: 30px;
  }
}
.hero-body-left {
  @include breakpoint(medium) {
    padding-right: 30px;
  }
}
// Cards
//-----------------------------------------------------

.card.card-default {
  background-color: $white;
}

p.card__date {
  font-size: 90%;
}

// TAGS
//-----------------------------------------------------

.widget {
  margin-bottom: 40px;
}

ul.tags {
  list-style: none;
  margin: 0;
  li {
    display: inline-block;
    margin: 0 5px 5px 0;
  }
  li a {
    color: $primary-color;
    font-weight: 500;
    text-transform: uppercase;
    font-size: rem-calc(12);
    padding: 2px 4px;
    border: 1px solid $primary-color;
    border-radius: 3px;
  }
}

// Custom Article
// ================================================================

.article__figcaption {
  padding: rem-calc(5 10);
  font-style: italic;
  font-size: $small-font-size;
  font-weight: 300;
}
.article__date {
  font-size: $small-font-size;
  font-weight: $global-weight-bold;
  text-transform: uppercase;
}
.article__author {
  font-size: $small-font-size;
  font-style: italic;
  font-weight: $global-weight-normal;
}
.article__series {
  @include callout();
  padding: 2rem;
  font-style: italic;
  a {
    font-family: "Archivo Narrow";
    font-size: 1.5em;
  }
}

// C2A
//-----------------------------------------------------

.c2a-button {
  text-align: center;
  padding: 1.5em 0;
  background-color: $primary-color;
  border-top: 1px solid lighten($primary-color, 5%);
  border-bottom: 1px solid darken($primary-color, 5%);
  #{$headers} {
    margin: 0;
    color: white;
  }
  @include breakpoint(medium) {
    width: 800px;
    max-width: 100%;
    margin: -2em auto 0;
    z-index: 2;
    position: relative;
  }
}

// Ports-1 - On index page
// ================================================================

.ports-1 {
  text-align: center;
  .fa {
    margin-bottom: 15px;
  }
  .card {
    background-color: $white;
    border-bottom: 2px solid darken($light-gray, 5%);
    margin-bottom: 0;
  }
  .card-section {
    padding: 20px;
    @include breakpoint(medium) {
      padding: 50px;
      border-right: 2px solid darken($light-gray, 5%);
    }
  }
}

// Custom Titles
// ================================================================
.title-1 {
  p {
    text-transform: uppercase;
    margin-bottom: 3px;
    font-size: 0.85em;
    font-weight: 400;
  }
  #{$headers} {
    color: $primary-color;
  }
  #{$headers} {
    &:after {
      content: "";
      //position: absolute;
      margin: 20px 0;
      width: 100%;
      height: 1px;
      display: block;
      background-color: darken($light-gray, 5%);
    }
  }
}
.title-2 {
  p {
    text-transform: uppercase;
    margin-bottom: 3px;
    font-size: 0.85em;
    font-weight: 400;
  }
  #{$headers} {
    color: white;
  }
  #{$headers} {
    &:after {
      content: "";
      //position: absolute;
      margin: 20px 0;
      width: 20%;
      height: 1px;
      display: block;
      background-color: white;
    }
  }
}

//Typography
//-----------------------------------------------------
.hand {
  font-family: "Indie Flower";
}
h2 {
  font-weight: 400;
}
strong {
  color: $dark-gray;
}
.lead {
  color: $dark-gray;
  font-weight: 400;
}
figure {
  margin-bottom: $global-margin;
}

// Section Client
//-----------------------------------------------------

section.clients {
  background: $light-gray;
  .row.logos {
    .column {
      background: white;
    }
    a {
      display: block;
      height: 150px;
      line-height: 150px;
      max-width: 30vw;
      margin: 0 auto;
    }
    .column {
      border-right: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
      @include breakpoint(small only) {
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6),
        &:nth-child(8) {
          border-right: none;
        }
        &:nth-child(7),
        &:nth-child(8) {
          border-bottom: none;
        }
      }
      @include breakpoint(medium) {
        &:nth-child(4),
        &:nth-child(8) {
          border-right: none;
        }
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          border-bottom: none;
        }
      }
    }
  }
}

// Galleries
//-----------------------------------------------------
.gallery-1 {
  img {
    background-color: white;
    padding: 4px;
    @include box-shadow();
  }
}

// Features
//-----------------------------------------------------

.features-type-1 {
  .feature {
    .feature--icon {
      display: block;
      width: 70px;
      float: left;
      height: 70px;
      background-color: darken($light-gray, 5%);
      border-radius: 3px;
      font-size: 25px;
      color: $body-font-color;
      line-height: 70px;
      text-align: center;
      margin-right: 20px;
      margin-top: 3px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
    .feature--text {
      float: left;
      width: 60%;
      width: calc(100% - 94px);
    }
    .feature--title {
      @extend h4;
      color: $body-font-color;
      font-family: $header-font-family;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}
.features-type-2 {
  .feature {
    .feature--icon {
      display: block;
      width: 70px;
      float: left;
      font-size: 25px;
      color: $primary-color;
      text-align: center;
      margin-right: 20px;
      margin-top: 3px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
    .feature--text {
      float: left;
      width: 60%;
      width: calc(100% - 94px);
    }
    .feature--title {
      @extend h4;
      color: $body-font-color;
      font-family: $header-font-family;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}
.features-type-3 {
  .feature {
    text-align: center;
    .feature--icon {
      display: block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50%;
      border: 1px solid $primary-color;
      font-size: 16px;
      margin: 0 auto 10px;
      color: $primary-color;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
    .feature--text {
    }
    .feature--title {
      @extend h4;
      color: $body-font-color;
      font-family: $header-font-family;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}
.features-type-4 {
  padding: (map-get($grid-column-gutter, small)) 0 0;
  @include breakpoint(large) {
    padding: (map-get($grid-column-gutter, large) + 20px) 0 0;
  }
  .feature {
    background-color: $white;
    padding: 2em;
    @include box-shadow();
    .feature--icon {
      display: none;
      font-size: 2em;
      color: $primary-color;
    }
    .feature--title {
      @extend h4;
      font-family: $header-font-family;
      font-weight: $global-weight-bold;
    }
    .feature--text {
      font-size: $small-font-size;
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Callouts
//-----------------------------------------------------

.callout {
  background-color: $light-gray;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left-color: $primary-color;
  p.title {
    font-size: rem-calc(18);
    font-weight: 400;
    margin-bottom: 0;
  }
}

.callout.light-gray {
  background-color: $light-gray;
  border: 0px solid $medium-gray;
}

// Forms
//-----------------------------------------------------

form.form {
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    border: 1px solid darken($light-gray, 8%);
  }
}

// Bottom
//-----------------------------------------------------

$box-margin: 3rem;
%list {
  list-style: none;
  margin-left: 0;
}
#bottom {
  .logo-bottom img {
    width: $logo-width;
    max-width: 65%;
    margin-bottom: $box-margin;
    opacity: 0.85;
  }
  h4 {
    margin-bottom: 10px;
  }
  hr {
    border-bottom: 1px solid rgba(white, 0.1);
  }
  p.description {
    margin-bottom: $box-margin;
  }
  ul.socials {
    margin-bottom: $box-margin;
    @extend %list;
    li {
      display: inline-block;
      margin-right: 20px;
    }
  }
  ul.resources {
    margin-bottom: $box-margin;
    @extend %list;
    li {
      margin-bottom: 0.75em;
    }
  }
  ul.get-in-touch {
    @extend %list;
    margin-left: 2rem;
    li {
      margin-bottom: 1.5em;
    }
    .icon {
      margin-right: 2rem;
    }
  }
}

/* Checkbox
================================================================ */

label.checkbox input {
  vertical-align: top;
  line-height: 1;
  padding: 0;
  margin-bottom: 10px;
  min-height: 25px;
  min-width: 25px;
}

// Pagenav
//-----------------------------------------------------

.pagenav a {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary-color;
  top: 50vh;
  transform: translateY(-50%);
  color: $white;
  height: 25vh;
  width: 100px;
  font-size: 48px;
  border: 1px solid $white;
}
.pagenav.left a {
  left: 0;
}
.pagenav.right a {
  right: 0;
}

// Stuff
//-----------------------------------------------------
.sidebar {
  background-color: rgba(black, 0.05);
}

img.round {
  border-radius: 50%;
}
p.card__date {
  margin-bottom: 5px;
}

.card-alt-1 .card__title {
  font-weight: 300;
}

.content-style {
  img {
    margin: 30px 0;
    @include breakpoint(large) {
      margin-left: -10%;
      max-width: 120%;
    }
  }
}

.bg-primary-stroke {
  background-color: rgba($light-gray, 0.5);
  border-bottom: 3px dotted $primary-color;
}
.pad-special {
  padding-top: 30px;
}

.card.white {
  background-color: white;
  text-align: center;
}

span.border {
  @include breakpoint(large) {
    background-color: $third-color;
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -4px;
    height: 4px;
  }
}

i.space {
  margin-right: 10px;
}

.simple-breadcrumbs {
  margin: 30px;
  font-size: rem-calc(13);
  text-transform: uppercase;
  color: $medium-gray;
  span.splitter {
    margin: 0 20px;
  }
}

.markbox {
  position: relative;
  width: 65px;
  height: 65px;
  margin: -10px auto 0;
  border-radius: 50%;
  border: 3px solid $light-gray;
  background-color: white;
  overflow: hidden;
  @include breakpoint(large) {
    width: 100px;
    height: 100px;
    margin: -70px auto -30px;
  }
}

.layout-path {
  background-color: black;
  color: white;
  padding: 3px 6px;
  font-size: rem-calc(12);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
}

.lazy-header {
  background-size: cover;
}

section.fullscreen {
  > .row {
    min-height: 100vh;
  }
}

.card {
  background-color: $light-gray;
  a.frame {
    display: block;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    div.overlay {
      @include overlay($bc: black, $c: white, $hover: fade);
    }
  }
  a:hover.frame {
    div.overlay {
      opacity: 0.8;
      width: 100%;
      top: 0;
    }
  }
  p.date {
    font-size: rem-calc(13);
    font-weight: $global-weight-bold;
  }
  a.title {
    color: $header-color;
    display: block;
    //text-transform: uppercase;
    font-weight: $global-weight-bold;
    margin-bottom: $global-margin;
  }
}

.card.article {
  background-color: $light-gray;
  border: 1px solid darken($light-gray, 10%);
  a {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: $black;
  }
}

ul.taxonomies {
  list-style: none;
  margin: 60px 0 30px;
  li {
    display: inline-block;
    background-color: $light-gray;
    text-transform: uppercase;
    margin-bottom: 3px;
    a {
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 0.05em;
      color: $dark-gray;
      display: block;
      padding: 0.5em 1em;
      @include breakpoint(medium) {
        font-size: 14px;
      }
    }
    a.active {
      background-color: $dark-gray;
      color: white;
    }
  }
}

.owl-carousel.logos {
  a {
    display: block;
    text-align: center;
    img {
      height: 35px;
      width: auto;
      margin: 0 auto;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      @include transition();
      @include breakpoint(medium) {
        height: 50px;
      }
    }
    &:hover img {
      -webkit-filter: none;
      filter: none;
      @include transition();
    }
  }
}

a.faq-link {
  display: block;
  text-transform: uppercase;
  font-size: rem-calc(13);
  margin-bottom: $global-margin;
}

ul.accordion {
  a.accordion-title {
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    //letter-spacing: 0.05em;
  }
  a.accordion-title,
  div.accordion-content {
    border-left: none;
    border-right: none;
  }
}

ul.tabs {
  text-align: center;
  padding: 20px 0;
  box-shadow: 0px 40px 80px -20px rgba($light-gray, 1);
  a,
  a:hover {
    color: $primary-color;
  }
  li {
    background: $light-gray;
    margin-bottom: 3px;
  }
  li.is-active a {
    font-weight: 700;
  }
  .tabs-title {
    float: none;
    display: inline-block;
  }
}

figcaption {
  padding: 0.5em 0 0.5em 1em;
  margin: 1em 0;
  font-style: italic;
}

.employees {
  .card {
    background-color: $light-gray;
    border: 1px solid $medium-gray;
  }
  img {
    width: 100px;
    height: 100px;
  }
  span.name {
    font-weight: $global-weight-bold;
    color: $header-color;
    font-size: rem-calc(16px);
    display: block;
  }
  span.jobtitle {
    text-transform: uppercase;
    font-size: rem-calc(12px);
    display: block;
    @include breakpoint(medium) {
      font-size: rem-calc(13px);
    }
  }
  span.phone,
  span.email {
    //text-transform: uppercase;
    font-size: rem-calc(15px);
    display: block;
    @include breakpoint(medium) {
      font-size: rem-calc(16);
      text-align: right;
    }
  }
}

p.divider {
  font-size: rem-calc(12);
  text-transform: uppercase;
  margin: 2rem 0;
  color: $primary-color;
  &:after {
    content: "";
    display: block;
    margin-top: -0.75em;
    border-top: 1px solid $light-gray;
  }
  span {
    background-color: $white;
    padding-right: 20px;
  }
}

p.service-items-title {
  font-weight: 300;
  color: $header-color;
}
ul.service-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li a {
    display: block;
    background-color: $light-gray;
    border: 1px solid $light-gray;
    text-align: center;
    padding: 30px 40px;
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    font-size: rem-calc(14);
    margin: 2px;
    &:hover {
      @include transition();
      border: 1px solid $medium-gray;
    }
  }
}

#befaring-time {
  display: none;
  &.visible {
    display: block;
  }
  hr {
    border-bottom-color: rgba(black, 0.1);
  }
}

form.botform {
  margin-top: 3em;
}

// Off-canvas
//-----------------------------------------------------

.off-canvas-menu {
  $color: rgba($white, 0.6);
  $color-active: $white;
  $bgColor: $primary-color;

  .off-canvas-menu--top {
    background-color: transparent;
    height: $top-height-small;
  }
  .off-canvas-menu--title {
    background-color: $accordionmenu-item-background;
    padding: $global-menu-padding;
    padding-top: 40px;
    font-size: rem-calc(28);
    font-weight: $global-weight-bold;
    color: $color;
    text-transform: uppercase;
  }
  span.burger-lines {
    @include hamburger(
      $white,
      $white,
      $burger-width,
      $burger-size,
      $burger-weight,
      3
    );
  }

  @include linear-gradient(to left, $bgColor, lighten($bgColor, 5%));
  z-index: 30;
  .accordion-menu {
    font-size: rem-calc(14);
    > li {
      border-bottom: 1px solid mix($bgColor, white, 88);
    }
    li a {
      color: $color;
      text-transform: uppercase;
      font-weight: $global-weight-normal;
      letter-spacing: 0.1em;
    }
    li li > a {
      text-transform: capitalize;
    }
    li.active > a {
      color: $color-active;
      font-weight: $global-weight-bold;
      background: $accordionmenu-item-background;
    }
  }
}

button.submenu-toggle {
  border-left: 1px solid #3c5690;
  border-radius: 0;
  &[aria-expanded="true"] {
    border-bottom: 1px solid #3c5690;
  }
}
// Botform Original
// ================================================================
.botform {
  background-color: $light-gray;
  display: none;
  overflow-y: auto;
  position: fixed;
  right: 0;
  z-index: 101;
  padding: 40px 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 5px 0 15px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 0 15px 10px rgba(0, 0, 0, 0.3);
  @include breakpoint(medium) {
    width: 80%;
  }
  @include breakpoint(large) {
    width: 50%;
  }
  .card {
    background-color: darken($light-gray, 5%);
  }
  .botform--close {
    position: absolute;
    background-color: transparent;
    height: 40px;
    line-height: 40px;
    width: 50px;
    display: block;
    right: 20px;
    top: 20px;
    border-radius: 3px;
    text-align: center;
    color: $primary-color;
    text-decoration: underline;
    cursor: pointer;
    z-index: 10;
    font-weight: 500;
    font-size: 0.85em;
    text-transform: uppercase;
  }
  .hr-botform {
    background-color: $light-gray;
    margin: 1em 0 1.5em 0;
  }
  .adress {
    display: none;
  }
  #{$headers} {
    color: $primary-color;
  }
}

.products1,
.products2,
.products3,
.products4 {
  background-color: $light-gray;
  display: none;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  padding: 40px 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 5px 0 15px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 0 15px 10px rgba(0, 0, 0, 0.3);
  .card {
    background-color: darken($light-gray, 5%);
  }
  .products--close {
    position: absolute;
    background-color: transparent;
    height: 40px;
    line-height: 40px;
    width: 50px;
    display: block;
    right: 20px;
    top: 20px;
    border-radius: 3px;
    text-align: center;
    color: $primary-color;
    text-decoration: underline;
    cursor: pointer;
    z-index: 10;
    font-weight: 500;
    font-size: 0.85em;
    text-transform: uppercase;
  }
  .hr-products {
    background-color: $light-gray;
    margin: 1em 0 1.5em 0;
  }
  .adress {
    display: none;
  }
  #{$headers} {
    color: $primary-color;
  }
}

.valid_date {
  color: $third-color;
  font-weight: 500;
}
