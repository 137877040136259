// Footer
// ================================================================

#footer {
    padding: $footer-padding;
    background-color: $footer-bg-color;
    color: $footer-color;
    @include color ($footer-color, $footer-header-color, $footer-link-color);
    p {
        margin: 0;
    }
    p.absoluttweb {
		margin-top: 1em;	
		@include breakpoint(medium) {
			margin: 0;
		}	
	}
}