// Section (Band)
// ================================================================
@mixin bg-boxed ($w:$wide-width,$mt:0) {
	width: $w;
	max-width: 100%;
    margin: 0 auto;
    position: relative;
    @include breakpoint(medium) {
    	margin-top: $mt;
    }
}