// Menu Mega
// ================================================================
@mixin reset() {
	margin: 0;
	padding: 0;
}
@mixin submenu-pos($top,$left) {
	top: $top;
	left: $left;
}
//Set padding between main items
$item-padding: 33px 20px 0;
//Set padding between sub items
$subitem-padding: 13px 18px;
//Set max width on dropdown
$max-width-drop-down: 320px;
//Set divider
$divider: 1px solid darken($light-gray,5%);

nav.menu-mega {
	font-family: $nav-family;
	font-weight: $nav-sublevel-font-weight;
	font-size: $nav-font-size;
	ul, ul * {
		@include reset();
		margin: 0 0 0 auto;
		display: block;
		list-style: none;
		box-sizing: border-box;
		@include transition(100ms);
	}
	ul {
		> li {
			float: left;
			//Sublevel Links
			ul li a {
				background-color: $nav-sublevel-bg;
				color: $nav-sublevel-color;
				text-transform: $nav-sublevel-text-transform;
				font-size: $nav-sublevel-font-size;
				padding: $subitem-padding;
				border-bottom: $nav-sublevel-border;
				&:hover {
					text-indent: 3px;
					color: $nav-sublevel-color-hover;
					background-color: $nav-sublevel-bg-hover;
				}
			}
			ul li.active a {
				color: $nav-sublevel-color-active;
				background-color: $nav-sublevel-bg-active;
			}
			ul li.column-title {
				color: $nav-sublevel-title;
			}
			// Drop Down Sublevel
			&.drop-down {
				position: relative;
				> ul {
					margin: 0;
					position: absolute;
					width: 100%;
					min-width: 245px;
					max-width: $max-width-drop-down;
					@include submenu-pos(100%, 0);	
					background: $nav-sublevel-bg;
					@include box-shadow(0, 40px, 80px, rgba(black,0.5));
					padding: 0;
					li {
						position: relative;
						width: 100%;
						&.drop-down {
							> a:after {
								font-family: "fa";
								content: '\f105';
								float: right;
							}	
							ul {
							position: absolute;
							@include submenu-pos(0, 100%);	 
							}
						}
					}
				}
			}
			// END Drop Down

			// Mega Menu
			&.combo {
				> ul {
					margin: 0;
					position: absolute;
					max-width: 100vw;
					left: 20px;
					right: 20px;	
					background: $nav-sublevel-bg;
					@include box-shadow(0, 40px, 80px, rgba(black,0.5));
					padding:10px 0;
					li {
						float: right;
						width: 100%;
						max-width: $max-width-drop-down;
						padding: $subitem-padding;
					}
					> li{
						border-left: solid 1px rgba(255, 255, 255, 0.1);
						&:last-child {
							border-left: none;
						}
					}
				}
				&.col-3 > ul > li {
					width: (100%/3);
				}
				&.col-4 > ul > li {
					width: (100%/4);
				}
				&.col-5 > ul > li {
					width: (100%/5);
				}
				&:hover {
					ul {
						opacity: 1;
						visibility: visible;						
					}
				}
				div.hero {
					position: absolute;
					right:0;
					bottom: 0;
					max-width: $max-width-drop-down;
				}
			}
			//END Mega Menu
		}
		// Hide Sublevels as Default
		li ul {
			visibility: hidden;
			opacity: 0;
			z-index: 1;
		}
	}
	li:hover {
		background: $nav-sublevel-bg;
		> a {
			color: $nav-sublevel-color-hover;
		}
		> ul {
			opacity: 1;
			visibility: visible;
		}
	}

	//Main Top Nav
	> ul > li > a {
		text-align: center;
		font-size: $nav-font-size;
		font-weight: $nav-font-weight;
		text-transform: $nav-text-transform;
		color: $nav-color;
		letter-spacing: $nav-letter-spacing;
		height: $top-height;
		line-height: $top-height;
		text-decoration: none;
		border-left: $divider;
		padding: $item-padding;
		position: relative;
		span.name {
			line-height: 1.5;
		}
	}
	> ul > li:last-child > a {
		border-right: $divider;
	}
	&.invert > ul > li > a { color: rgba($white, 0.8) }
	
	> ul > li:hover {
		background-color: $nav-background-hover;
		> a {
			color: $nav-color-hover;
		}
	}
	&.invert > ul > li:hover > a { color: $white; }
	
	> ul > li.active {
		> a {
			color: $nav-color-active;
			font-weight: $nav-font-weight-active;
			background-color: $nav-background-active;
		}
	}
	&.invert > ul > li.active > a { color: $white }
}