// Top - Foundation Flex version
// ================================================================

section.top {
	background-color: $top-bg-color;
	&.trans {
		background-color: transparent;
		border-bottom: 1px solid rgba($white, 0.1)
	}
	position: relative;
	z-index: 5;
	// Set height of header, first ROW
	> .row:nth-child(1) {
		height: $top-height-small;
		@include breakpoint($breakpoint) {
			height: $top-height;
		}
	}
	// Set size of logo
    a.logo {
		display: block;
		width: $logo-width-small;
		@include breakpoint($breakpoint) {
			width: $logo-width;
		}
	}
	// Build burger lines
	span.burger-lines {
		@include hamburger($burger-color, $burger-color-active, $burger-width, $burger-size, $burger-weight, 3);
	}
	&.invert span.burger-lines {
		@include hamburger(rgba($white,0.6), rgba($white,0.6), $burger-width, $burger-size, $burger-weight, 3);
	}
	// Show burger title to left of burger if activated
	span.burger-title {
		display: inline-block;
		font-size: rem-calc(13);
		font-weight: $global-weight-bold;
		color: $burger-color;
		text-transform: uppercase;
		margin-right: 10px;
    }
    &.invert span.burger-title {
    	color: rgba($white,0.6);
    }
    a.top-icon {
    	color: $top-icon-color;
    	margin-right: 30px;
    	&:last-child {
    		margin: 0;
    	}
    }
    &.invert a.top-icon {
    	color: rgba($white,0.6);
    }
}
// Animate in when it gets stuck
.is-stuck {
	@include transition(500ms);
	@keyframes top-slide-in {
    	from {top: -200px;}
    	to {top: 0;}
	}
	animation: top-slide-in 300ms 1;
}