.relative {
	position: relative;
}

// Typography
// ============================================================

.ls, .letter-spacing {
	letter-spacing: 0.07em;
}
.up, .uppercase {
	text-transform: uppercase;
}
.underline {
	text-decoration: underline
}

.lc, .lowercase {
	text-transform: lowercase;
}
.thin, .thin {
	font-weight: 300;
}
.thick, .fat, .strong, .bold {
	font-weight: 600;
}

// Margin
// ============================================================

.no-marg {
	margin: 0 ;
}
.no-marg-bot, .mb-0 {
	margin-bottom: 0 ;
}
.marg-bot {
	margin-bottom: $global-margin ;
}
.marg-bot-2x {
	margin-bottom:($global-margin * 2) ;
}
.marg-bot-triple, .marg-bot-3x, .marg-bot-xlarge {
	margin-bottom: ($global-margin * 3);
}
.marg-bot-quadrouple, .marg-bot-4x, .marg-bot-xxlarge {
	margin-bottom: ($global-margin * 4);
}
.marg-bot-half, .marg-bot-small {
	margin-bottom: ($global-margin / 2) ;
}
.marg-top {
	margin-top: $global-margin ;
}
.marg-top-double, .marg-top-2x, .marg-top-large {
	margin-top: ($global-margin * 2);
}
.marg-top-triple, .marg-top-3x, .marg-top-xlarge {
	margin-top: ($global-margin * 3);
}
.marg-top-quadrouple, .marg-top-4x, .marg-top-xxlarge {
	margin-top: ($global-margin * 4);
}
.marg-top-half, .marg-top-small {
	margin-top: ($global-margin / 2);
}
.marg-right {
	margin-right: 20px ;
}
.marg-left {
	margin-left: 20px ;
}

// Paddings

.pad-all {
	padding: $band-padding/2;
	@include breakpoint(medium) {
		padding: $band-padding;
	}
}
.pad {
	padding-top: $band-padding /2;
	padding-bottom: $band-padding /2;
	@include breakpoint(medium) {
		padding-top: $band-padding;
		padding-bottom: $band-padding;
	}
}
.pad-fix {
	padding-top: $band-padding /2;
	padding-bottom: ($band-padding /2) - $global-margin;
	@include breakpoint(medium) {
		padding-top: $band-padding;
		padding-bottom: $band-padding - $global-margin;;
	}
}
.pad-half {
	padding: $band-padding/2 0;
}
.pad-2x {
	padding: $band-padding*2 0;
}
.pad-top {
	padding-top: $band-padding /2;
	@include breakpoint(medium) {
		padding-top: $band-padding;
	}
}

.pad-top-half, .pad-half-top {
	padding-top: $band-padding / 2;
}
.pad-bot {
	padding-bottom: $band-padding;
}
.pad-bot-half, .pad-half-bot {
	padding-bottom: $band-padding / 2;
}