// Bottom
// ================================================================

section.bottom {
    background-color: $bottom-bg-color;
    color: $bottom-color;
    @include color ($bottom-color, $bottom-header-color, $bottom-link-color);
    *[class^="fa-"] {
        color: $bottom-icon-color;
    }
}